import $ from "jquery";
import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = [ 'main', 'select', 'mainSelect' ]

  setItems(event) {
    let url = this.mainTarget.dataset.url
    let optionSelected = $(this.selectTarget).val()

    console.log(optionSelected)
    get(`${url}?warehouse_id=${optionSelected}`, { responseKind: 'turbo-stream' })
  }
}