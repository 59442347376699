import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "form", "text" ]

  connect() {
  }

  edit(event) {
    event.currentTarget.style.display = "none"
    this.textTarget.style.display = "none"
    this.formTarget.style.display = "block"
  }
}