import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "search",
    "container",
    "total",
    "form",
    "badQuantityInput",
    "commentSelect",
    "switchIncremental",
  ];

  submit(event) {
    this.formTarget;
  }

  connect() {
    this.checkNoItems();
  }

  receive(event) {
    event.preventDefault();
    const element = document.getElementById(
      `${event.target.dataset.sku}_good_quantity`
    );
    const itemSKU = element.dataset.sku.trim();
    const itemValue = element.value;
    if (itemValue == 0) return;

    const itemCard = document.getElementById(`${itemSKU}_item_card`);

    if (itemCard.classList.contains("!hidden")) {
      document.getElementById(`${itemSKU}_input`).value = itemValue;
      document.getElementById(`${itemSKU}_value`).innerHTML = itemValue;
    } else {
      document.getElementById(`${itemSKU}_input`).value =
        parseInt(document.getElementById(`${itemSKU}_input`).value) +
        parseInt(itemValue);
      document.getElementById(`${itemSKU}_value`).innerHTML =
        parseInt(document.getElementById(`${itemSKU}_value`).innerHTML) +
        parseInt(itemValue);
    }

    element.value = "";
    itemCard.classList.remove("!hidden");
    this.objectTransition(itemCard, "item-reverse-card--transition");
    this.updateReceivedBtn();
    this.checkNoItems();
  }

  toggleNokForm(event) {
    let accordionContent = document.getElementById(
      `${event.target.dataset.sku}_not_ok_form`
    );
    if (accordionContent.classList.contains("!hidden")) {
      accordionContent.classList.remove("!hidden");
    } else {
      accordionContent.classList.add("!hidden");
    }
  }

  submitNokForm(event) {
    event.preventDefault();

    const element = document.getElementById(
      `${event.target.dataset.sku}_good_quantity`
    );
    const itemSKU = element.dataset.sku.trim();

    const badQuantity = this.badQuantityInputTarget.value;
    const comment = this.commentSelectTarget.value;

    if (
      badQuantity == 0 ||
      badQuantity >
        parseInt(document.getElementById(`${itemSKU}_value`).innerHTML)
    )
      return;

    const resultDiv = document.getElementById(
      `${event.target.dataset.sku}_resultNokForm`
    );
    const damageItemsDiv = document.getElementById(
      `${event.target.dataset.sku}_resultNokForm_value`
    );
    const reasonDiv = document.getElementById(
      `${event.target.dataset.sku}_resultNokForm_reason`
    );
    damageItemsDiv.innerHTML = `${badQuantity}`;
    reasonDiv.innerHTML = `${comment}`;
    resultDiv.classList.remove("hidden");

    document
      .getElementById(`${event.target.dataset.sku}_nok_form`)
      .classList.add("!hidden");
  }

  receiveInput(event) {
    if (event.keyCode === 13) {
      this.receive(event);
    }
  }

  receiveAutomatic(event) {
    if (event.keyCode !== 13) return;

    if (!this.switchIncrementalTarget.checked) return;

    let items = Array.from(
      document.querySelectorAll(".custom-table__row")
    ).filter((item) => {
      return !item.classList.contains("!hidden");
    });

    if (items.length > 1) return;

    let sku = items[0].dataset.sku;
    document.getElementById(`${sku}_good_quantity`).value = 1;
    document.getElementById(`${sku}_btn_receive`).click();
    document.getElementById(`${sku}_good_quantity`).value = null;
    this.searchTarget.value = "";
  }

  removeReceived(event) {
    const itemSKU = event.target.dataset.sku;
    document.getElementById(`${itemSKU}_item_card`).classList.add("!hidden");
    document.getElementById(`${itemSKU}_value`).innerHTML = "";
    document.getElementById(`${itemSKU}_input`).value = "";
    this.updateReceivedBtn();
    this.checkNoItems();
  }

  objectTransition(element, className) {
    element.classList.add(className);
    setTimeout(() => {
      element.classList.remove(className);
    }, 1000);
  }

  checkNoItems() {
    const itemsCard = document.querySelectorAll(".item-reverse-card");

    if (![...itemsCard].every((item) => item.classList.contains("!hidden"))) {
      document.getElementById("empty_cards").classList.add("!hidden");
    } else {
      document.getElementById("empty_cards").classList.remove("!hidden");
    }
  }

  updateReceivedBtn() {
    let itemsValues = document.querySelectorAll(".items_values");
    let total = Array.from(itemsValues).reduce((accumulator, currentValue) => {
      let value = parseInt(currentValue.innerHTML);
      if (!isNaN(value)) {
        return accumulator + value;
      }

      return accumulator;
    }, 0);
    const receivedBtn = document.getElementById("rceive_btn");
    receivedBtn.textContent = `Receber (${total})`;
  }

  search(event) {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.searchValue(
        this.searchTarget.value,
        this.searchTarget.dataset.values
      );
    }, 500);
  }

  searchValue(searchTerm, values) {
    const valuesParsed = JSON.parse(values);
    const filteredValues = valuesParsed.filter((value) => {
      let attribute = value.stock_inventory_attributes.stock_item_attributes;
      return (
        attribute.external_id
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        attribute.name
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    });

    document.querySelectorAll(".custom-table__row").forEach((element) => {
      if (
        filteredValues.some(
          (obj) =>
            obj.stock_inventory_attributes.stock_item_attributes.external_id.toString() ===
            element.dataset.sku.toString()
        )
      ) {
        element.classList.remove("!hidden");
      } else {
        element.classList.add("!hidden");
      }
    });
  }
}
