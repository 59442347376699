import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field" ]

  remove(event) {
    if (event.target.classList.contains('input-field__input--is-invalid')) {
      event.target.classList.remove('input-field__input--is-invalid')
      this.fieldTarget.querySelector('.input-field__invalid-feedback').remove()
    }
  }

  removeSelect(event) {
    let field = this.fieldTarget.querySelector('.field_with_errors .input-field__input');
    
    if (field.classList.contains('input-field__input--is-invalid')) {
      field.classList.remove('input-field__input--is-invalid')
      this.fieldTarget.querySelector('.input-field__invalid-feedback').remove()
    }
  }

}