import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "expand", "content" ];
    
  onExpand(){
    if(this.expandTarget.classList.contains("fa-chevron-up")){
      this.expandTarget.classList.add("fa-chevron-down");
      this.expandTarget.classList?.remove("fa-chevron-up");
  
    }else{
      this.expandTarget.classList.add("fa-chevron-up");
      this.expandTarget.classList?.remove("fa-chevron-down");
    }
  }
}