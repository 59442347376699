import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="highlight"
export default class extends Controller {
  static targets = [ 'field' ]

  connect() {
    this.highlight(this.fieldTarget.dataset.searchResult, this.fieldTarget)
  }

  highlight(searched, target) {
    if (searched === "" || searched === undefined) return

    let text = target.innerHTML;
    let re = new RegExp("(" + searched + ")","gi");
    let newText = text.replace(re, '<b>$1</b>');
    target.innerHTML = newText;
  }
}
