import { Controller } from "stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = [ "main" ]

  connect() {
    window.location.href = this.mainTarget.dataset.url
  }
}
