import { Controller } from "stimulus";
import $ from "jquery";
import "select2";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["image"];

  connect() {
    const image = $(this.element).data("image");
    $(this.element).select2({
      disabled: this.element.dataset.disabled,
      closeOnSelect: false,
      placeholder: this.placeholder,
      allowHTML: true,
      allowClear: this.allowClear,
      language: {
        noResults: function () {
          return "Sem Resultados";
        },
      },
      templateSelection: function (content) {
        if (image) {
          return $(
            '<span class="w-full h-full flex items-center" ><img src="' +
            image +
            '" class="mr-2" style = max-width:25px;/> <p class="select2-results__text">' +
            content.text +
            '</p><i class="fa-regular fa-chevron-down absolute right-4"></i></span>'
          );
        } else {
          return content.text;
        }
      },
      templateResult: function (content) {
        if (image) {
          return $(
            '<span class="flex items-center text-sm w-10/12"><img src="' +
            image +
            '"/ style = max-width:25px;> <p class="select2-results__text">' +
            content.text +
            "</p></span>"
          );
        } else {
          return content.text;
        }
      },
    });

    if (this.element.dataset.onchange === "submit") {
      $(this.element).on(
        "select2:select select2:unselect select2:clear",
        function (e) {
          Rails.fire(this.closest("form"), "submit");
        }
      );
    }

    if (!this.element.dataset.event || this.element.dataset.event === "")
      return;

    let selectElement = this.element;
    $(this.element).on("change", function (event) {
      selectElement.dispatchEvent(new CustomEvent(selectElement.dataset.event));
    });
  }
  disconnect() {
    $(this.element).select2("destroy");
  }
  get placeholder() {
    return this.element.dataset.placeholder || "Selecione";
  }
  get allowClear() {
    return this.element.dataset.allowClear || true;
  }
  get multiple() {
    return this.element.dataset.multiple || "multiple";
  }
}
