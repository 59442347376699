import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["btnInStore", "btnInHome", "formPartial"];

  connect() {
    this.btnInStoreTarget.classList.add("button--primary");
  }

  inStoreForm() {
    this.formPartialTarget.innerHTML =
      this.btnInStoreTarget.getAttribute("data-parial-params");
    this.btnInHomeTarget.disabled = false;
    this.btnInStoreTarget.disabled = true;
    this.btnInHomeTarget.classList.remove("button--primary");
    this.btnInStoreTarget.classList.add("button--primary");
  }

  inHomeForm() {
    this.formPartialTarget.innerHTML =
      this.btnInHomeTarget.getAttribute("data-parial-params");
    this.btnInHomeTarget.disabled = true;
    this.btnInStoreTarget.disabled = false;
    this.btnInHomeTarget.classList.add("button--primary");
    this.btnInStoreTarget.classList.remove("button--primary");
  }
}
