import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="autoremove"
export default class extends Controller {
  static targets = ["element"];
  connect() {
    setTimeout(() => {
      this.elementTarget.remove();
    }, 500);
  }
}
