import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

// Connects to data-controller="shipping-home"
export default class extends Controller {
  static targets = [ "mainDiv" ]

  attributesChanged(event) {
    post(this.mainDivTarget.getAttribute('data-url'), {
      responseKind: 'turbo-stream',
      body: JSON.stringify(
        {
          order: {
            ship_address: document.getElementById('order_ship_address').value,
            ship_city: document.getElementById('order_ship_city').value,
            ship_province: document.getElementById('order_ship_province').value,
            ship_zip: document.getElementById('order_ship_zip').value,
            ship_company: document.getElementById('order_ship_company').value,
            order_id: this.mainDivTarget.getAttribute('data-id'),
            partial: 'loopos/order_partials/shopify/summary-details',
            container_id: 'summary_details',
          },
          unpersisted: true
        }
      )
    })
  }
}
