// app/javascript/controllers/modal_controller.js

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dialog", "input", "content", "action"];

  open(event) {
    try {
      this.dialogTarget.classList.add("modal-open");
      this.dialogTarget.style.display = "block";
      // @TODO: change for event.params.content
      this.contentTarget.innerHTML = event.target.getAttribute(
        "data-modal-content-param"
      );
      this.inputTarget.innerHTML = event.target.getAttribute(
        "data-modal-input-param"
      );
      document.body.classList.add("overflow-hidden");
      if (
        event.target.getAttribute("data-modal-action-param") &&
        event.target.getAttribute("data-modal-action-param") == "false"
      ) {
        this.actionTarget.style.display = "none";
      }
    } catch (e) {}
  }

  close(event) {
    try {
      this.dialogTarget.classList.remove("modal-open");
      this.dialogTarget.style.display = "none";
      if (this.contentTarget) this.contentTarget.innerHTML = "";
      this.inputTarget.innerHTML = "";
      document.body.classList.remove("overflow-hidden");
    } catch (e) {}
  }
}
