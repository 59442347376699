// app/javascript/controllers/pickup_controller.js

import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["error", "initial", "success", "value", "errorMsg", "packageCode", "orderCode", "shippingCode", "previouState", "currentState", "form"]

  onSubmit (event) {
    Rails.fire(this.formTarget, 'submit');
  }

  afterSubmit(event) {
    const [, , xhr] = event.detail;
    let params = JSON.parse(xhr.responseText)

    // not the best way but for now it will prevail.
    // @TODO: Refactor this 
    if (params.hasOwnProperty('error')) {
      this.initialTarget.style.display = "none"
      this.successTarget.style.display = "none"
      this.errorTarget.style.display = "flex"
      this.errorMsgTarget.textContent = params.error
    } else {
      this.errorTarget.style.display = "none"
      this.initialTarget.style.display = "none"
      this.successTarget.style.display = "flex"
      this.packageCodeTarget.textContent = params.package.tracking_number
      this.shippingCodeTarget.textContent = params.package.shipping_id
      this.previouStateTarget.textContent = params.old_status
      this.currentStateTarget.textContent = params.package.status
    }
  }
} 