import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["submit"];

  connect() {
    let checkboxs = Array.from(
      document.querySelectorAll("input[type='checkbox']")
    );

    let checkboxsChecked = checkboxs.filter((item) => {
      return item.checked;
    });

    if (checkboxs.length == checkboxsChecked.length) {
      this.submitTarget.disabled = false;
      this.submitTarget.classList.remove("button--disabled");
      this.submitTarget.classList.add("button--primary");
    } else {
      this.submitTarget.disabled = true;
      this.submitTarget.classList.remove("button--primary");
      this.submitTarget.classList.add("button--disabled");
    }
  }

  check() {
    let checkboxs = Array.from(
      document.querySelectorAll("input[type='checkbox']")
    );

    let checkboxsChecked = checkboxs.filter((item) => {
      return item.checked;
    });

    if (checkboxs.length == checkboxsChecked.length) {
      this.submitTarget.disabled = false;
      this.submitTarget.classList.remove("button--disabled");
      this.submitTarget.classList.add("button--primary");
    } else {
      this.submitTarget.disabled = true;
      this.submitTarget.classList.remove("button--primary");
      this.submitTarget.classList.add("button--disabled");
    }
  }
}
