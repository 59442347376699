import { Controller } from "stimulus";
import $ from "jquery";
import "select2";
import Rails from "@rails/ujs";
import { get, post } from "@rails/request.js";

export default class HubSelect extends Controller {
  static targets = ["select"];

  connect() {
    $(this.selectTarget).select2({
      closeOnSelect: false,
      placeholder: this.placeholder,
      allowHTML: true,
      allowClear: true,
    });

    const callbackFunc = this.paramsChanged;
    const target = this.selectTarget;

    $(this.selectTarget).on("select2:select", function (e) {
      let submitBtn = document.getElementById("costumerSubmit");
      if (submitBtn) {
        submitBtn.classList.add("button--disabled");
        console.log(submitBtn);
      }

      let options = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        responseKind: "application/json",
      };

      get(`/hubs/${this.value}`, options)
        .then(function (res) {
          return res.json;
        })
        .then(function (data) {
          document.getElementById("order_ship_latitude").value =
            data.hub.latitude;
          document.getElementById("order_ship_longitude").value =
            data.hub.longitude;
          document.getElementById("order_ship_address").value =
            data.hub.address;
          document.getElementById("order_ship_city").value =
            data.hub.district.name;
          document.getElementById("order_ship_province").value =
            data.hub.district.name;
          document.getElementById("order_ship_zip").value = data.hub.zip_code;
          document.getElementById("order_ship_phone").value = data.hub.phone;
          document.getElementById("order_ship_company").value = data.hub.name;
          document.getElementById("order_ship_name").value =
            data.hub.brand.name;
          document.getElementById("order_ship_hub_code").value =
            data.hub.external_code;
          document.getElementById("order_ship_hub_name").value = data.hub.name;

          callbackFunc(target);
        });
    });

    callbackFunc(target);
  }
  disconnect() {
    $(this.element).select2("destroy");
  }
  get placeholder() {
    return this.element.dataset.placeholder || "Selecione";
  }
  get allowClear() {
    return this.element.dataset.allowClear || true;
  }
  get multiple() {
    return this.element.dataset.multiple || "multiple";
  }

  paramsChanged(target) {
    post(target.getAttribute("data-url"), {
      responseKind: "turbo-stream",
      body: JSON.stringify({
        order: {
          ship_address: document.getElementById("order_ship_address").value,
          ship_city: document.getElementById("order_ship_city").value,
          ship_province: document.getElementById("order_ship_province").value,
          ship_zip: document.getElementById("order_ship_zip").value,
          ship_company: document.getElementById("order_ship_company").value,
          order_id: target.getAttribute("data-id"),
          ship_hub_id: document.getElementById("order_ship_hub_id").value,
          partial: "loopos/order_partials/shopify/summary-details",
          container_id: "summary_details",
        },
        unpersisted: true,
      }),
    }).then((val) => {
      let submitBtn = document.getElementById("costumerSubmit");
      if (submitBtn) {
        submitBtn.classList.remove("button--disabled");
      }
    });
  }
}
