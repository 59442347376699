import { Controller } from "stimulus";

export default class extends Controller {
  static minChars = process.env.MIN_CHARACTER_SEARCH || 4;
  static targets = ["form", "field", "scope"];

  connect() {
    let input = this.fieldTarget;
    let len = input.value.length;
    input.setSelectionRange(len, len);
  }

  submit(event) {
    let input = event.currentTarget.value;
    if (!this.validInput(input)) return;

    clearTimeout(this.timeout);
    this.changeTurboFrame();

    this.timeout = setTimeout(
      () => {
        this.formTarget.requestSubmit();
      },
      input.length == 0 ? 500 : 1000
    );
  }

  changeScope() {
    try {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      if (this.fieldTarget.value == "" || this.fieldTarget.value == null) {
        this.scopeTarget.value = null;
      } else {
        this.scopeTarget.value = params.scope;
      }
    } catch (e) {
      console.error("scope not defined", e);
    }
  }

  validInput(input) {
    return input.length > this.constructor.minChars || input.length == 0;
  }

  changeTurboFrame() {
    if (this.fieldTarget.value == "" || this.fieldTarget.value == null) {
      this.formTarget.setAttribute(
        "data-turbo-frame",
        this.formTarget.getAttribute("data-initial-turboframe")
      );
    } else {
      this.formTarget.setAttribute(
        "data-turbo-frame",
        this.formTarget.getAttribute("data-results-turboframe")
      );
    }
  }
}
