import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const button = document.getElementById("payment-edit-confirm");
    button?.classList.add("button--disabled");
    button.setAttribute("disabled", true);
  }

  input(event) {
    const button = document.getElementById("payment-edit-confirm");

    if (event.srcElement.value != "") {
      button?.classList.remove("button--disabled");
      button.removeAttribute("disabled");
    } else {
      button?.classList.add("button--disabled");
      button.setAttribute("disabled", true);
    }
  }
}
