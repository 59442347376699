import { Controller } from "stimulus";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/js/utils.js";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.intlInput = intlTelInput(this.inputTarget, {
      nationalMode: true,
      separateDialCode: true,
      preferredCountries: ["pt"],
    });

    let infoForm = document.getElementById("order-info-form");
    if (infoForm) {
      infoForm.addEventListener("submit", () => {
        this.inputTarget.value = window.intlTelInputGlobals
          .getInstance(this.inputTarget)
          .getNumber();
      });
    }
  }
}
