import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form']

  connect() {
  }

  save() {
    Turbo.navigator.submitForm(this.formTarget)
  }
}