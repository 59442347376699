// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["btn"]

    encrypt(event) {
        const encryptedDivs = document.querySelectorAll('.encrypted');
        const decryptedDivs = document.querySelectorAll('.decrypted');


        if (encryptedDivs[0].classList.contains('hidden')) {

            encryptedDivs.forEach(div => {
                div.classList.remove('hidden');
            });
            decryptedDivs.forEach(div => {
                div.classList.add('hidden');
            });
            this.btnTarget.textContent = this.btnTarget.dataset.view;

        } else {

            encryptedDivs.forEach(div => {
                div.classList.add('hidden');
            });
            decryptedDivs.forEach(div => {
                div.classList.remove('hidden');
            });
            this.btnTarget.textContent = this.btnTarget.dataset.hide;
            console.log(this.btnTarget.dataAttribute.hide)
        }

    }
}