import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "step1Edit",
    "step1",
    "step2",
    "step3",
    "locationSelect",
    "providerSelect",
    "locationInput",
    "providerInput",
    "emptyCard",
    "mainContent",
    "search",
    "btnConfirmDetails",
    "btnSubmit",
    "switchIncremental",
  ];

  connect() {
    if (!this.locationSelectTarget.value || !this.providerSelectTarget.value) {
      return;
    }

    $(this.locationSelectTarget).prop("disabled", false);
    this.btnConfirmDetailsTarget.disabled = false;
  }

  editDetails(event) {
    $(this.locationSelectTarget).prop("disabled", false);
    $(this.providerSelectTarget).prop("disabled", false);
    this.btnConfirmDetailsTarget.innerHTML = "Confirmar";
    this.btnConfirmDetailsTarget.dataset.action =
      "click->reverse-shippings#setDetails";
  }

  enableProviderSelect(event) {
    $(this.providerSelectTarget).prop("disabled", false);
  }

  enableConfirmButton(event) {
    if (!this.locationSelectTarget.value || !this.providerSelectTarget.value) {
      return;
    }

    this.btnConfirmDetailsTarget.disabled = false;
  }

  setDetails(event) {
    if (!this.locationSelectTarget.value || !this.providerSelectTarget.value) {
      return;
    }

    if (this.step1Target.dataset.noStep != "true") {
      this.step2Target.classList.remove("hidden");
    }

    this.btnConfirmDetailsTarget.innerHTML = "Editar";
    this.btnConfirmDetailsTarget.dataset.action =
      "click->reverse-shippings#editDetails";
    $(this.locationSelectTarget).prop("disabled", true);
    $(this.providerSelectTarget).prop("disabled", true);

    this.locationInputTarget.value = this.locationSelectTarget.value;
    this.providerInputTarget.value = this.providerSelectTarget.value;

    this.checkSaveRequirements(event);
  }

  receiveAutomatic(event) {
    if (event.keyCode !== 13) return;

    if (!this.switchIncrementalTarget.checked) return;

    let items = Array.from(
      document.querySelectorAll(".custom-table__row")
    ).filter((item) => {
      return !item.classList.contains("!hidden");
    });

    if (items.length == 0 || items.length > 1) return;

    let sku = items[0].dataset.sku;
    document.getElementById(`${sku}_quantity`).value = 1;
    document.getElementById(`${sku}_btn`).click();
    document.getElementById(`${sku}_quantity`).value = null;
    event.currentTarget.value = "";
  }

  addQuantity(event) {
    let inputSource = document.getElementById(event.currentTarget.dataset.id);
    if (!inputSource) return;

    let inputTarget = document.getElementById(inputSource.dataset.inputTarget);
    if (!inputTarget) return;

    let value = parseInt(inputTarget.value) + parseInt(inputSource.value);
    if (value > parseInt(inputTarget.dataset.max) || value <= 0 || !value)
      return;

    this.emptyCardTarget.classList.add("hidden");
    inputTarget.value = value;

    this.checkSaveRequirements(event);

    let cardElement = document.getElementById(inputTarget.dataset.target);
    if (!cardElement) return;

    cardElement.classList.remove("!hidden");
    let label = cardElement.getElementsByClassName("items_values")[0];

    label.innerHTML = inputTarget.value;
  }

  remove(event) {
    let inputTarget = document.getElementById(
      event.currentTarget.dataset.target
    );

    if (!inputTarget) return;

    inputTarget.value = 0;

    this.checkSaveRequirements(event);

    let cardElement = document.getElementById(inputTarget.dataset.target);
    if (!cardElement) return;

    cardElement.classList.add("!hidden");

    let emptyItems = Array.from(
      document.getElementsByClassName("items_cards")
    ).every((item) => {
      return item.classList.contains("!hidden");
    });

    if (!emptyItems) return;
    this.emptyCardTarget.classList.remove("hidden");
  }

  checkSaveRequirements(event) {
    let packagesNumberInput = document.getElementById(
      "shippings_reverse_shipping_packages_number"
    );
    let packagesNumberGreaterThanZero = parseInt(packagesNumberInput.value) > 0;
    let providerInput = document.getElementById(
      "shippings_reverse_shipping_provider_id"
    );
    let providerInputNotNull = !!providerInput.value;

    let shipableInput = document.getElementById(
      "shippings_reverse_shipping_shippable_id"
    );
    let shipableInputNotNull = !!shipableInput.value;

    let goodQuantitiesInputs = Array.from(
      document.getElementsByClassName("good_quantities_inputs")
    );
    let badQuantitiesInputs = Array.from(
      document.getElementsByClassName("bad_quantities_inputs")
    );

    let anyGreaterThanZero =
      goodQuantitiesInputs.some((el) => parseInt(el.value) > 0) ||
      badQuantitiesInputs.some((el) => parseInt(el.value) > 0);

    if (anyGreaterThanZero) {
      this.step3Target.classList.remove("hidden");
    } else {
      this.step3Target.classList.add("hidden");
    }

    if (
      packagesNumberGreaterThanZero &&
      providerInputNotNull &&
      shipableInputNotNull &&
      anyGreaterThanZero
    ) {
      this.btnSubmitTarget.disabled = false;
    } else {
      this.btnSubmitTarget.disabled = true;
    }
  }

  search(event) {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      let searchedValue = event.target.value;
      console.log(searchedValue);
      let searchableCards = Array.from(
        document.getElementsByClassName("searchable")
      );
      if (!searchedValue) {
        searchableCards.forEach((item) => {
          item.classList.remove("!hidden");
        });

        return;
      }

      searchableCards.forEach((item) => {
        let values = JSON.parse(item.dataset.search);
        let founded = values.some((value) => {
          return value
            .toString()
            .toLowerCase()
            .includes(searchedValue.toLowerCase());
        });
        if (founded) {
          item.classList.remove("!hidden");
        } else {
          item.classList.add("!hidden");
        }
      });
    }, 500);
  }
}
