import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["value"]

  decrement() {
    this.valueTarget.stepDown()
  }

  increment() {
    this.valueTarget.stepUp()
  }
}
