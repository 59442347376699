import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['field1', 'field2']

  connect() {
    const button = document.getElementById("payment-edit-confirm");
    button?.classList.add("button--disabled");
    button.setAttribute("disabled", true);
  }

  input(event) {
    const button = document.getElementById("payment-edit-confirm");

    if (this.field1Target.value != "" && this.field2Target.value != "") {
      button?.classList.remove("button--disabled");
      button.removeAttribute("disabled");
    } else {
      button?.classList.add("button--disabled");
      button.setAttribute("disabled", true);
    }
  }
}
