import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["box", "icon", "input"];

  toggle() {

    [...document.getElementsByClassName('select-box__card')].forEach(card => {
      card.style["border-color"] = '#F4F4F5';
    });
    [...document.getElementsByClassName('fa-circle-check')].forEach(card => {
      card?.classList?.remove('fa-circle-check')
    })

    //TODO: WORK WITH OTHER PAYMENTS
    this.inputTarget.value = event.target.getAttribute('data-value')
    document.getElementById("icon_" + this.inputTarget.value).classList?.add('fa-circle-check');
    document.getElementById("box_" + this.inputTarget.value).style["border-color"] = "#0B6EF9";

    [...document.getElementsByClassName('payment-validation')].forEach(paymentForm => {
      if (!paymentForm?.classList?.contains("!hidden")) {
        paymentForm?.classList.add("!hidden")
      }
    });

    this.element.nextElementSibling?.classList.remove("!hidden");

  }
}
