
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["main"];

  toggle(ev) {
    this.mainTarget.src = ev.target.src;
  }

}
